    <template>
  <div class="job_recommend_wrap">
    <div class="job_recommend">
      <div class="banner">
        <img
          src="~/static/activity/jobFair-country/banner.png"
          alt=""
          title="高校残疾人毕业生就业就业帮扶"
        />
      </div>
      <div class="contents">
        <div class="crumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path: '/activity/jobFair'}">活动首页</el-breadcrumb-item>
            <el-breadcrumb-item>就业政策</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <img src="~/static/activity/jobFair-country/title5.png" alt="" />
        <span>选择地区：</span>
        <ThreeLink
          :codeType="'provinceid'"
          :codeLevel="'2'"
          placeholder="请选择地区"
          class="select"
          @setMValue="setScasValue($event,page,['provinceid','city'])"
        ></ThreeLink>
        <h1>
          <img src="@/static/news/policy.png" alt="" class="icon img" />政策法规
        </h1>
        <div class="content flex">
          <div class="left">
            <!-- <ul class="tab" v-if="locationSite != '河南省'">
              <li v-for="(n, i) in tabList" :key="i" @click="tabs(i, n)">
                <span :class="{ active: active == i }">{{ n }}</span>
              </li>
            </ul> -->
            <ul class="list">
              <li
                class="box"
                v-for="(n, i) in list"
                :key="i"
                @click="goRouter('/news/newDetail', n.id)"
              >
                <h2 class="line_clamp2">
                  <a href="javascript:void(0);">{{ n.title }}</a>
                </h2>
                <div class="bottom flex">
                  <img
                    :src="n.thumbnail"
                    alt=""
                    class="banner"
                    v-if="n.thumbnail"
                  />
                  <div class="info relative">
                    <div class="text line_clamp2">
                      {{ n.conAbstract }}
                    </div>
                    <div class="time">
                      {{ n.createTime }}
                      <span>{{ n.titleAbbr }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <status :type="status.type" v-if="status.isShow"></status>
            <div class="pages">
              <el-pagination
                background
                class="work_pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.pageNum"
                :page-size="page.pageSize"
                layout="prev, pager, next, total, jumper"
                v-show="page.total > 10"
                :total="page.total"
                prev-text="上一页"
                next-text="下一页"
              >
              </el-pagination>
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div class="title">
                <h2>新闻动态</h2>
                <i
                  class="icon icon el-icon-d-arrow-right"
                  @click="goRouter('/news/new_list')"
                  title="更多"
                ></i>
              </div>
              <ul>
                <li class="first flex">
                  <div class="info" v-for="(n, i) in list.slice(0, 2)" :key="i">
                    <img
                      :src="n.thumbnail"
                      :alt="n.titile"
                      :title="n.title"
                      class="banner err_image"
                    />
                    <a
                      href="javascript:void(0);"
                      :title="n.title"
                      class="line_clamp1"
                      @click="goRouter('/news/newDetail', n.id)"
                      >{{ n.title }}</a
                    >
                  </div>
                </li>
                <li
                  v-for="(n, i) in list.slice(2, 8)"
                  :key="i"
                  class="li line_clamp1"
                >
                  <a
                    href="javascript:void(0);"
                    :title="n.title"
                    @click="goRouter('/news/newDetail', n.id)"
                  >
                    <span v-show="n.author">「{{ n.author }}」</span
                    >{{ n.title }}
                  </a>
                </li>
              </ul>
            </div>
            <div v-for="(n, i) in adList" :key="i" class="relative">
              <router-link :to="n.picUrl" target="_blank" :title="n.adName">
                <img
                  :src="n.picSrc"
                  alt="广告"
                  :title="n.adName"
                  class="ad_img"
                />
              </router-link>
              <span>广告</span>
            </div>
            <div class="box">
              <div class="title">
                <h2>通知公告</h2>
                <i
                  class="icon icon el-icon-d-arrow-right"
                  @click="goRouter('/news/msg_list')"
                  title="更多"
                ></i>
              </div>
              <ul>
                <li v-for="(n, i) in list2" :key="i" class="li line_clamp1">
                  <a
                    href="javascript:void(0);"
                    :title="n.title"
                    @click="goRouter('/news/newDetail', n.id)"
                  >
                    <span v-show="n.author">「{{ n.author }}」</span
                    >{{ n.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThreeLink from "@/components/selectComponents/ThreeLink";
export default {
  name: "index",
  components: {
    ThreeLink,
  },
  data() {
    return {
      active: 0,
      tabList: ["国家级", "本省", "本市"],
      list: [],
      list1: [],
      list2: [],
      adList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        city: "",
        leve: "本省",
        consultationClassName: "政策法规",
      },
      loading: false,
      locationSite: "",
      status: {
        type: "record",
        isShow: "",
      },
    };
  },
  methods: {
    //三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
        this.news();
        this.policy();
        this.message();
      }
    },
    tabs(i, val) {
      this.active = i;
      this.page.leve = val;
      this.policy();
    },
    //政策
    policy() {
      this.loading = true;
      this.$api
        .newsListApi(this.page)
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list = res.data.data.records;
            this.page.total = res.data.data.total;
          }
          if (this.list <= 0) {
            this.status.isShow = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.status.type = "wifi";
          this.status.isShow = true;
        });
    },
    //新闻
    news() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          city: this.page.city,
          leve: this.page.leve,
          consultationClassName: "新闻动态",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list1 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //通知
    message() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          city: this.page.city,
          leve: this.page.leve,
          consultationClassName: "通知公告",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list2 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //广告
    getAdApi() {
      this.$api
        .adApi({ classId: "40288113735a75d901735a75d9a40000" })
        .then((res) => {
          this.adList = res.data.data;
        });
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.policy();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    let locationText = localStorage.getItem("locationSelect");
    this.locationSite = localStorage.getItem("locationSite");
    if (locationText) {
      if (locationText == "全国") {
        this.page.city = "";
      } else {
        this.page.city = locationText;
      }
    }
    if (this.locationSite === "河南省") {
      this.page.leve = "本省";
      this.page.city = locationText || "郑州市";
      this.$emit("goRouter", this.$route.query.url);
    } else if (this.locationSite === "湖南省") {
      console.log(this.$route.query.url);
      this.$emit("goRouter", this.$route.query.url);
    } else if (this.locationSite == "陕西省") {
      this.page.leve = "本省";
      this.page.city = "西安市";
    }
    this.policy();
    this.news();
    this.message();
  },
  mounted() {
    this.$emit("goRouter", "/news/");
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/news.css";
/deep/.el-cascader .el-input .el-input__inner {
  width: 120px;
  height: 30px;
  border: 1px solid rgba(0, 18, 10, 0.1);
  background: transparent;
}
h1 {
  padding-top: 30px;
  font-size: 24px;
}
.content {
  margin-top: 20px;

  .left {
    flex: 6;

    .tab {
      height: 50px;
      line-height: 50px;
      background: #f2f2f2;
      padding: 0 35px;
      display: flex;

      li {
        margin-right: 20px;

        span {
          padding: 5px 10px;
          cursor: pointer;
        }
      }

      li span.active {
        font-size: 12px;
        color: white;
        background: #000;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
    }

    .list {
      margin-top: 10px;

      .box {
        max-height: 190px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        h2 {
          font-size: 18px;
          padding: 20px 0;
          cursor: pointer;
        }

        .bottom {
          position: relative;

          .banner {
            width: 190px;
            height: 110px;
            min-width: 190px;
            min-height: 110px;
            margin-right: 18px;
          }

          .info {
            padding-right: 40px;

            .text {
              color: #666;
              min-height: 24px;
            }

            .time {
              margin-top: 10px;
              // position: absolute;
              // left: 0;
              // bottom:0;
              // color: #666666;

              span {
                display: inline-block;
                padding: 0 5px;
                margin-left: 35px;
                font-size: 12px;
                background: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }

  .right {
    width: 330px;
    margin-left: 20px;

    .box {
      flex: 1;
      margin-bottom: 20px;
      .title {
        position: relative;
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
        background: #f2f2f2;

        h2 {
          position: relative;
          padding-left: 10px;
          font-size: 22px;
          font-weight: 400;
        }

        h2::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 22px;
          background: #000;
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          color: #000;
          cursor: pointer;
        }
      }

      .first {
        margin: 10px 0;
        padding: 0 10px;

        .info {
          flex: 1;
          margin-right: 10px;

          img {
            width: 100%;
            height: 145px;
            min-width: 100%;
            min-height: 145px;
            margin: 0 auto;
          }

          img:last-child {
            margin-right: 0;
          }
        }
      }

      .li {
        position: relative;
        padding: 0 20px;
        margin-top: 10px;
        cursor: pointer;
      }

      .li::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background: #333333;
      }
    }

    .ad {
      margin: 20px 0;

      img {
        width: 100%;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 5px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        color: white;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .people {
    .box {
      margin-bottom: 30px;
    }
  }
}
img {
  width: 24px;
  margin-right: 16px;
}
.job_recommend_wrap {
  background: #fffaf3;
  box-sizing: border-box;
  padding-bottom: 48px;
  .job_recommend {
    .banner {
      img {
        width: 100%;
      }
    }
    .contents {
      width: 1200px;
      margin: 0 auto;
      .crumb {
        height: 22px;
        margin: 36px 0 48px 0;
      }
      > img {
        margin-bottom: 27px;
        width: 100%;
      }
      span {
      }
      .select {
        width: 120px;
      }
    }
  }
}
</style>